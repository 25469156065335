<template>
  <div class="actions">
    <img
      :src="photo"
      class="avatar-xl rounded-circle"
    >
  </div>
</template>
    
<script>
    const placeholder = require('../../../assets/img/logo256.png')
    export default {
        props: {object: {type: Object, required: true}},
        data(){
            return  {
                placeholder
            }
        },
        computed: {
            hasPhoto(){
                if(this.object === null || this.object === undefined) return false
                console.log(this.object)
                return this.object.photo !== null && this.object.photo !== undefined
            },
            photo(){
                if(this.hasPhoto) {
                    console.log(this.object.photo)
                    let p = JSON.parse(this.object.photo)
                    return p.url
                }
                return this.placeholder
            }
        }
    }
    </script>
    
    <style>
    
    </style>